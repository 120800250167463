export const environment = {
  production: false,
  acceptance: true,
  test: false,
  dev: false,
  local: false,
  api: 'https://api.acc.rallygo.eu/api',
  images: 'https://images.acc.rallygo.eu',
  web: 'https://rallygo.mpc.best',
  services: {
    core: `https://core.api.acc.rallygo.eu/v3`,
    payments: `https://payments.api.acc.rallygo.eu/v3`,
    // core: 'http://localhost:8080/v3',
    // payments: 'http://localhost:8085/v3'
  }
};

import { SuccessComponent } from './payments/success/success.component';
import { FailureComponent } from './payments/failure/failure.component';
import { Routes } from '@angular/router';
import { SimpleComponent } from './layouts/simple/simple.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: SimpleComponent,
    children: [
        {
        path: 'upload',
        loadChildren:
          () => import('./deposit/deposit.module').then(m => m.DepositModule)
      },
      // {
      //   path: 'fee/:id',
      //   loadChildren: () => import('./fee/fee.module').then(m => m.FeeModule)
      // },
      {
      path: 'donate',
      pathMatch: 'full',
      loadChildren: () => import('./donate/donate.module').then(m => m.DonateModule)
    },{
      path: 'donate/:slug',
      loadChildren: () => import('./donate-event-details/donate-event-details.module').then(m => m.DonateEventDetailsModule)
    },
      { path: 'payment-failure', component: FailureComponent },
      { path: 'payment-success', component: SuccessComponent },
      { path: '**', redirectTo: '/donate' }
    ]
  },

  { path: '**', redirectTo: '/donate' }
];

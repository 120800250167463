export class Team {
    _id: string;
    uid: string;
    name: string;
    slug: string;
    desc: string;
    icon: string;
    img: string;
    banner: string;
    avatar: string;
    members: any[];

    city: string;
    country: string;

    gps: {
        coordinates: number[],
        type: string,
        date: Date,
        updated: boolean
    };

    users: any[];
    contact: any[];

    createdAt: Date;
    updatedAt: Date;

    fullName?: string;
}

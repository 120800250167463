import { HttpClient } from "@angular/common/http";
import { AppService } from "./app.service";
import { Injectable, EventEmitter } from "@angular/core";
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from "@angular/router";
import { User } from "../models/user";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLogged: EventEmitter<any>;
  isLoggedIn: boolean;
  jwtHelper = new JwtHelperService();
  private user: any;

  constructor(
    private app: AppService,
    private router: Router,
    private http: HttpClient) {
    this.isLogged = new EventEmitter();
    this.isLoggedIn = false;

    this.isLogged.subscribe( logged => {
      if ( logged === false) {
        this.isLoggedIn = false;
      } else {
        this.isLoggedIn = true;
      }
    });
  }

  init() {
    if (localStorage.User) {
      this.user = JSON.parse(localStorage.User);
      this.validToken().subscribe( token => {
        this.isLogged.emit( this.user );
        this.isLoggedIn = true;

      }, err => {
        console.log('ValidToken Error', err);
        this.setLoggedOff();
      });
    }
  }

  logIn(email: string, passwd: string) {
    email = String(email).trim().toLowerCase();
    passwd = String(passwd).trim();

    return this.http.post(this.app.api() + '/auth/login', {
      email: email,
      passwd: passwd
    });
  }

  logOut() {
    this.setLoggedOff();
  }

  signIn( body: any ) {
    return this.http.post( this.app.api() + '/auth/register', body );
  }

  signInConfirm( body: any ) {
    return this.http.post( this.app.api() + '/auth/register-confirm', body );
  }

  validToken() {
    return this.http.post(this.app.api() + '/auth/token/validation', {});
  }

  isAuthenticated() {
    try {
      const token = localStorage.getItem('Token');
      return !this.jwtHelper.isTokenExpired(token);

    } catch( e ) {
      return false;
    }
  }

  setUser(user: any) {
    this.user = user;
    localStorage.User = JSON.stringify(user);
  }

  setToken(token: string) {
    console.log('set token', token);
    localStorage.setItem('Token', token);
  }

  setAuth(email: String, password: String) {
    localStorage.Auth = JSON.stringify({
      email: email,
      passwd: password
    });
  }

  getAuth() {
    if (!localStorage.Auth) {
      return false;
    }

    return JSON.parse(localStorage.Auth);
  }

  setLoggedIn(user: any) {
    this.user = user;
    localStorage.setItem('User', JSON.stringify(user));
    this.isLogged.emit( user );
    this.isLoggedIn = true;
  }

  setLoggedOff() {
    this.clearStorage();
    this.isLoggedIn = false;
    this.isLogged.emit( false );
    this.router.navigate(['auth','login']);
  }

  getUser(): User {
    return JSON.parse(localStorage.User);
  }

  resetPasswordRequest( email, captcha ) {
    const body = {
      email: email,
      captcha: captcha,
      mobile: true
    };

    return this.http.post(this.app.api() + '/auth/reset-password-request', body);
  }

  resetPasswordConfirm( email, code ) {
    const body = {
      email: email,
      code: code,
      mobile: true
    };

    return this.http.post(this.app.api() + '/auth/reset-password-confirm', body);
  }

  resetPasswordChange( email, hash, passwd ) {
    const body = {
      email: email,
      hash: hash,
      passwd: passwd
    };

    return this.http.put(this.app.api() + '/auth/reset-password-change', body);
  }

  private clearStorage() {
    localStorage.removeItem('User');
    localStorage.removeItem('_assets_id');
    localStorage.removeItem('_rally_camps');
    localStorage.removeItem('Token');
    localStorage.removeItem('rallies');
    localStorage.removeItem('PushToken');
    localStorage.removeItem('Assets');
    localStorage.removeItem('event');
    //localStorage.removeItem('_rally');

    //const auth = localStorage.Auth;
    //const settings = localStorage.settings;
    //const rally_settings = localStorage._rally_settings || null;

  }
}

import { Component, OnInit } from '@angular/core';
import {AppService} from "../../services/app.service";
import {Router} from "@angular/router";
import {EventsService} from "../../services/events.service";
import { MatDialog } from '@angular/material';
import {Event} from "../../models/event";

@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.scss']
})
export class DonateComponent implements OnInit {

  link: any;
  selectedTeam: string;
  currency: any;
  events: any;

  isLoading: boolean;
  isError: any;

  constructor(
      public app: AppService,
      private router: Router,
      public dialog: MatDialog,
      private evSvc: EventsService
  ) { }

  ngOnInit(): void {
    // Update forms if page loaded with ?event= & team= params
    this.link = this.app.queryParams();
    this.selectedTeam = this.link.team;
    this.app.viewingEventDetails = false;

    this.app.currencies().subscribe( (c: any[]) => {
      this.currency = c;
    });

    this.loadEvents();
  }

  onViewEvent(event) {

  }

  async loadEvents() {
    this.isLoading = true;
    const query = {
      status: 'open,ongoing',
      limit: 0,
      fundraise: true
    };

    try {
      const events = await this.evSvc.gwtFundraises().toPromise<any>();
      console.log('events', events);
      this.events = events.sort(this._sort_object);
      this.isLoading = false;
    } catch (err) {
        this.isLoading = null;
        this.isError = 'YES'
        console.error(err);
    }
  }

  private _sort_object( a, b ) {
    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
  }

}


<div class="buttons">
    <button [matMenuTriggerFor]="lang" mat-icon-button class="m-r-5">
        <mat-icon>language</mat-icon>
    </button>
    <button  mat-button  class="m-r-5" [routerLink]="'/upload'">
        <mat-icon>upload</mat-icon> {{ 'UPLOAD_FILE' | translate }}
    </button>

    <button *ngIf="app.viewingEventDetails" class="share-button" mat-button (click)="onShare()">{{ 'SHARE' | translate }}</button>
</div>


<mat-menu #lang="matMenu">
    <button mat-menu-item (click)="onSetLang('pl')">
        Polski
    </button>
    <button mat-menu-item (click)="onSetLang('en')">
        English
    </button>
</mat-menu>

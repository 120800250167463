import { AppService } from './app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(
    private http: HttpClient,
    private app: AppService) {

  }

  accountDetails( account: string ) {
    return this.http.get( this.app.services.payments + `/stripe/account/${account}` );
  }

  makePayment(body: any ) {
    return this.http.post( this.app.services.payments + `/stripe/checkout/donate`, body);
  }

}

export class Fundraise {
    amount: number;
    amount_target: number;
    total_amount: number;
    currency: string;
    name: string;
    lead: string;
    organization: Organization;
    event: EventFundraisePopulated;
    description: string;
    image: string;
    slug: string;

    createdAt: Date;
    updatedAt: Date;
    enabled: boolean;
    stripeAccount: string;
    availability: {
        from: Date,
        until: Date
    };

    _id: string;
}

export interface EventFundraisePopulated {
    _id: string;
    name: string;
    description: string;
    image: string;
    slug: string;
    logo: string;
    start: {
        place: string,
        city: string,
        date: Date,
        type: string;
        coordinates: [number, number], // [ <long>, <lat> ]
        desc: string,
    };

    finish: {
        city: string,
        place: string,
        date: Date,
        type: string,
        coordinates: [number, number], // [ <long>, <lat> ]
        desc: string
    };
}

export interface Organization {
    _id: string;
    name: string;
    image: string;
    logo: string;
    slug: string;
    type: string;
    events: number;
    followers: number;
}

import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AppService} from '../../../services/app.service';
import {EventFundraisePopulated, Fundraise} from "../../../models/fundraise";

@Component({
  selector: 'app-event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.scss'],
})
export class EventItemComponent implements OnInit {

  @Input() fundraise: Fundraise;
  @ViewChild('background', { static: true }) background: ElementRef<HTMLInputElement>;

  event: EventFundraisePopulated;
  backgroundUrl: string;

  constructor(
      private app: AppService
  ) {
    this.backgroundUrl = '/assets/imgs/event-bg.jpg';
  }

  ngOnInit() {
      if (this.fundraise.image) {
        this.backgroundUrl = this.app.fixImage(this.fundraise.image);
      }

      this.background.nativeElement.style.backgroundImage = `url("${this.backgroundUrl}")`;
      this.event = this.fundraise.event;
  }

  onView() {

  }

  get progress(): number {
    return this.fundraise.amount_target > 0 ? (this.fundraise.amount / this.fundraise.amount_target) * 100 : 0;
  }

}

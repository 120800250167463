import { NgModule } from '@angular/core';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { MaterialModule } from '../material-module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TeamSummaryComponent } from './team-summary/team-summary.component';
import { EventSummaryComponent } from './event-summary/event-summary.component';
import { PaymentDialogComponent } from './payment-dialog/payment-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { LinkDialogComponent } from './link-dialog/link-dialog.component';
import { DrapdropDirective } from './drapdrop.directive';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ProgressComponent } from './progress/progress.component';
import { LoaderComponent } from './loader/loader.component';
import { EventItemComponent } from './event-item/event-item.component';
import { MomentModule } from 'ngx-moment';
import { StripePaymentDialogComponent } from './stripe-payment-dialog/stripe-payment-dialog.component';
import { QRCodeModule } from 'angularx-qrcode';
import {ProgressBarComponent} from "./progress-bar/progress-bar.component";

@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
      MomentModule,
      QRCodeModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    TeamSummaryComponent,
    EventSummaryComponent,
    PaymentDialogComponent,
    LinkDialogComponent,
    DrapdropDirective,
    FileUploadComponent,
    ProgressComponent,
    LoaderComponent,
    EventItemComponent,
    StripePaymentDialogComponent,
    ProgressBarComponent,
  ],
    exports: [
        AccordionAnchorDirective,
        AccordionLinkDirective,
        AccordionDirective,
        TeamSummaryComponent,
        EventSummaryComponent,
        DrapdropDirective,
        FileUploadComponent,
        ProgressComponent,
        LoaderComponent,
        EventItemComponent,
        StripePaymentDialogComponent,
        ProgressBarComponent,
    ],
  providers: [  ]
})
export class SharedModule { }

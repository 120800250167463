import { Component, OnInit, Input } from '@angular/core';
import { Event } from '../../../models/event';

@Component({
  selector: 'app-event-summary',
  templateUrl: './event-summary.component.html',
  styleUrls: ['./event-summary.component.css']
})
export class EventSummaryComponent implements OnInit {

  @Input() event: Event;

  constructor() { }

  ngOnInit(): void {
    console.log( this.event );

  }

}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AppService} from "../../../services/app.service";
import {StripeService} from "../../../services/stripe.service";

@Component({
  selector: 'app-stripe-payment-dialog',
  templateUrl: './stripe-payment-dialog.component.html',
  styleUrls: ['./stripe-payment-dialog.component.css']
})
export class StripePaymentDialogComponent implements OnInit {
  errors: any;

  constructor(
      private app: AppService,
      private stripeSvc: StripeService,
      public dialogRef: MatDialogRef<StripePaymentDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  async ngOnInit() {

    try {
      const payment = await this.stripeSvc.makePayment(this.data).toPromise<any>();
      window.location.assign(payment.url);
    } catch (err) {
      console.error(err);
      this.errors = err;
    }

  }

}

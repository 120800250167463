<div class="main" (click)="onView()">
  <div class="date">
      {{ fundraise.availability.from | amDateFormat:'Do MMMM ddd., hh:mm' }} -
      {{ fundraise.availability.until | amDateFormat:'Do MMMM ddd., hh:mm' }}
  </div>
  <div #background class="picture"></div>
  <div class="details" >
      <div class="title">
          {{ fundraise.name }}
      </div>
      <div class="progress">
            <app-progress-bar [amount]="fundraise.total_amount" [amount_target]="fundraise.amount_target" [currency]="fundraise.currency"></app-progress-bar>
      </div>
</div>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  public services: any = {}

  constructor(
    private http: HttpClient,
    private app: AppService) {
  }

  get( query: any ) {
    const params: HttpParams = new HttpParams({fromObject: query});
    return this.http.get( this.app.services.core + `/events`, {params: params});
  }

  getById( id: string ) {
    return this.http.get( this.app.services.core + `/events/${id}` );
  }

  getFundraiseById( id: string ) {
    return this.http.get( this.app.services.core + `/fundraises/${id}` );
  }

  getFundraiseBySlug( slug: string ) {
    return this.http.get( this.app.services.core + `/fundraises/slug/${slug}` );
  }

  gwtFundraises() {
    return this.http.get( this.app.services.core + `/fundraises` );
  }

  participants( id: string, status: string, query: any = null) {
    const params: HttpParams = new HttpParams({fromObject:  query });

    return this.http.get( this.app.services.core + `/events/${id}/participants`, {params: params});
  }

}

<div fxLayout="column" fxLayoutAlign="center center">
    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
        <mat-card class="oh text-center little-profile">
            <mat-card-content>
                <mat-card-title>{{ 'DEPOSIT.TITLE' | translate }}<span
                        class="bg-success text-white rounded font-12 pl-5 pr-5">Beta</span></mat-card-title>
                <mat-card-subtitle>{{ 'DEPOSIT.SUBTITLE' | translate }}</mat-card-subtitle>
            </mat-card-content>
        </mat-card>

        <div class="loader" *ngIf="isLoading === true">
            <mat-spinner> </mat-spinner>
            <p>{{'LOADING_EVENTS' | translate}}</p>
        </div>
        <div class="error" *ngIf="isError">
            <p>{{'LOADING_EVENTS_ERROR' | translate}} 😡</p>
        </div>
        <div *ngIf="isLoading === false">
            <div class="event-empty" *ngIf="events?.length === 0">
                <mat-card class="text-center">
                    <mat-card-content>
                        <mat-card-subtitle>{{ 'NO_EVENTS' | translate }}</mat-card-subtitle>
                    </mat-card-content>
                </mat-card>
            </div>

            <div *ngFor="let ev of events" class="event-item" [routerLink]="['/donate', ev.slug]">
                <app-event-item [fundraise]="ev"></app-event-item>
                <p>{{ app.removeHTMLtags(ev.lead) }}</p>
            </div>
        </div>

    </div>
</div>

<div mat-dialog-title>
    <span>{{'DIALOG.SHARE.TITLE' | translate}}</span>
</div>
<div mat-dialog-content>
    <div class="code">
        <p>{{link}}</p>
    </div>
    <div class="buttons">
        <button type="button" class="copy-button" (click)="onCopy()">
            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="copy" class="svg-inline--fa fa-copy fa-w-14 jss151" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM352 32.491a15.88 15.88 0 0 1 7.431 4.195l51.882 51.883A15.885 15.885 0 0 1 415.508 96H352V32.491zM288 464c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V144c0-8.822 7.178-16 16-16h80v240c0 26.51 21.49 48 48 48h112v48zm128-96c0 8.822-7.178 16-16 16H176c-8.822 0-16-7.178-16-16V48c0-8.822 7.178-16 16-16h144v72c0 13.2 10.8 24 24 24h72v240z"></path></svg>
            <span>{{'DIALOG.SHARE.COPY' | translate}}</span>
        </button>
    </div>
    <div class="qr">
        <qrcode [qrdata]="link" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
    </div>
    <hr />
    <div class="sharable">
        <button type="button" class="fb" (click)="shareOn('fb')">{{'DIALOG.SHARE.FB' | translate}}</button>
        <button type="button" class="tw" (click)="shareOn('tw')">{{'DIALOG.SHARE.TW' | translate}}</button>
    </div>
</div>

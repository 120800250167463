<div class="progress-container">
    <div class="progress-bar" [style.width]="progress + '%'"></div>
</div>

<div class="progress-text">
    {{
        'COLLECTED' | translate:{
            amount: (amount / 100) | currency: currency:'':'1.0-0',
            amount_total: (amount_target / 100) | currency: currency:'':'1.0-0',
            currency: currency
        }
    }}
</div>
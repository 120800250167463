<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container">
    <mat-toolbar color="primary" class="topbar telative">

        <!-- ============================================================== -->
        <!-- Logo - style you can find in header.scss -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand" href="index.html">
                <span fxShow="false" fxShow.gt-xs>
                    <img src="assets/logo_kajgo.svg" class="rallygo-logo light-logo" alt="homepage">
                </span>
            </a>

            <span fxFlex></span>
        </div>
        <span fxFlex></span>
        <!-- ============================================================== -->
        <!-- app header component - style you can find in header.scss / header.component.ts-->
        <!-- ============================================================== -->
        <app-header></app-header>
    </mat-toolbar>

    <mat-sidenav-container [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
        <mat-sidenav-content class="page-wrapper">
            <div class="page-content">
                <router-outlet>
                    <app-spinner></app-spinner>
                </router-outlet>


            </div>
            <div fxLayout="column wrap"  fxLayoutAlign="center center" class="company-footer">
                <p>
                    <a href="https://kajgo.com/terms-of-use" target="_blank">{{'EULA' | translate}}</a> |
                    <a href="https://kajgo.com/privacy-policy" target="_blank">{{'PRIVACY_POLICY' | translate}}</a>
                </p>
                <hr />
                <p><b>©2024  by 2GO Systems OÜ</b></p>
                <p>Registered under :  14961466</p>
                <p>Address:  A.H Tammsaare tee 47</p>
                <p>11316 Tallinn, Estonia</p>
                <p>E-mail: <a href="mailto:support@kajgo.com?subject=KajGO Donations">support@kajgo.com</a></p>
                <p><a href="https://kajgo.com">KajGO</a></p>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>

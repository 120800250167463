<div mat-dialog-title>
    <span>{{'DIALOG.PAYMENT.TITLE' | translate}}</span>
</div>
<div mat-dialog-content>
    <div *ngIf="form">
        <form id="paymentForm" #paymentForm action="" method="" name="paymentForm">
            <input type="hidden" name="{{param.name}}" value="{{param.value}}" *ngFor="let param of formParams" />
        </form>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="oh text-center little-profile">
        <div *ngIf="!errors">
            <div fxFlex="100" class="text-center">
                <mat-progress-bar mode="indeterminate" color="warn"></mat-progress-bar>
                <p>{{'DIALOG.PAYMENT.SENDING_REQUEST' | translate}}</p>
            </div>
        </div>
        <div *ngIf="errors">
            <mat-icon class="error-icon" color="warn">warning</mat-icon>
            <p>{{'MESSAGES.PAYMENT_FAILURE' | translate}}</p>

            <button mat-raised-button color="accent" [mat-dialog-close]="false">{{'BACK' | translate}}</button>
        </div>

        <a href="" id="link" name="link" #link style="display: none">link</a>
    </div>
</div>
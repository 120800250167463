import { Component, OnInit, Input } from '@angular/core';
import { Team } from '../../../models/team';

@Component({
  selector: 'app-team-summary',
  templateUrl: './team-summary.component.html',
  styleUrls: ['./team-summary.component.css']
})
export class TeamSummaryComponent implements OnInit {

  @Input() team: Team;

  constructor() { }

  ngOnInit(): void {
    console.log( this.team );
  }

}

import { Fundraise } from './fundraise';
import { User } from './user';


export class Event {
    _id: string;
    org: string;
    slug: string;
    stripeAccountId: string;

    name: string;
    lead: string;
    desc: string;
    subscription: string;
    image: string;

    page: string;
    reg: string;
    regurl: string;

    status: string;
    type: string;

    fundraise: Fundraise;

    fee: {
        amount: number,
        currency: string
    };

    start: {
        place: string,
        city: string,
        date: Date,
        type: string;
        coordinates: [number, number], // [ <long>, <lat> ]
        desc: string,
    };

    finish: {
        city: string,
        place: string,
        date: Date,
        type: string,
        coordinates: [number, number], // [ <long>, <lat> ]
        desc: string
    };

    contact: [{
        fname: string,
        lname: string,
        phone: string,
        email: string
    }];

    users: [User];
    sb: {
        fb: string,
        tw: string,
        is: string,
        web: string
    };

    tags: [string]
}



import { LinkDialogComponent } from './../../shared/link-dialog/link-dialog.component';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import {AppService} from "../../../services/app.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class AppHeaderComponent implements OnInit {
  event: string;

  constructor(
      private translate: TranslateService,
      private dialog: MatDialog,
      public app: AppService,
      public auth: AuthService) {
  }

  async ngOnInit() {

  }

  onSetLang( lang ) {
    this.translate.setDefaultLang( lang );
    this.translate.use( lang );
  }

  onShare() {
    const dialogRef = this.dialog.open(LinkDialogComponent, {
      width: '600px',
      closeOnNavigation: false,
      disableClose: false,
      data: {
        fundraise: localStorage.getItem('fundraise'),
        event: localStorage.getItem('event'),
        team: localStorage.getItem('team'),
        participant: localStorage.getItem('team')
      }
    });
  }
}

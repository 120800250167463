<div fxLayout="row wrap" fxLayoutAlign="center center">
    <!-- Card column -->
    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
        <mat-card class="oh text-center little-profile">
            <mat-card-content>
                <mat-card-title>{{ 'DEPOSIT.TITLE' | translate }}<span class="bg-success text-white rounded font-12 pl-5 pr-5">Beta</span></mat-card-title>
                <mat-card-subtitle>{{ 'DEPOSIT.SUBTITLE' | translate }}</mat-card-subtitle>

                <div fxLayout="row">
                    <div fxFlex>
                        <p>
                            <mat-icon class="big-icon">warning</mat-icon>
                        </p>
                        <p>{{'MESSAGES.PAYMENT_FAILURE' | translate}}</p>
                    </div>
                </div>

                <button mat-raised-button color="accent" routerLink="/deposit">{{'BACK' | translate}}</button>

            </mat-card-content>
        </mat-card>
    </div>
</div>
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent {
  @Input() amount: number = 0;
  @Input() amount_target: number = 10000;
  @Input() currency: string = 'EUR';

  get progress(): number {
    return this.amount_target > 0 ? (this.amount / this.amount_target) * 100 : 0;
  }
}
import {Component, Inject, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { ClipboardService } from 'ngx-clipboard'
import {MatSnackBar} from "@angular/material/snack-bar";
import {AppService} from "../../../services/app.service";

@Component({
  selector: 'app-link-dialog',
  templateUrl: './link-dialog.component.html',
  styleUrls: ['./link-dialog.component.scss']
})
export class LinkDialogComponent implements OnInit {
  participant: string;
  event: string;
  fundraise: string;
  link: string;

  constructor(
      private app: AppService,
      private clipboard: ClipboardService,
      private snack: MatSnackBar,
      public dialog: MatDialog,
      public dialogRef: MatDialogRef<LinkDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any) {
        this.link = '';
        console.log(data);
  }

  onCopy() {
    this.clipboard.copy( this.link );
    this.snack.open(this.app.translate('DIALOG.SHARE.COPY_CONFIRM'), 'OK!', {
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 2500
    });
  }

  shareOn(service) {
    let url = null;

    switch (service) {
      case 'fb': url = `https://www.facebook.com/sharer/sharer.php?u=${this.link}`; break;
      case 'tw': url = `https://twitter.com/intent/tweet?url=${this.link}`; break;
    }

    if (url) {
      window.open(url, '_blank').focus();
    }
  }

  ngOnInit(): void {
    this.participant = this.data.participant === 'null' ? null : this.data.participant;
    this.event = this.data.event;
    this.fundraise = this.data.fundraise;


    this.link = `${window.location.protocol}//${window.location.host}/donate/${this.fundraise}`;

    if (this.participant) {
      this.link += `?participant=${this.participant}&rgo`
    }
  }


}

import { Injectable, EventEmitter } from '@angular/core';
import { AppService } from './app.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(
    private http: HttpClient,
    private app: AppService
  ) { }

  participant(id: string) {

    return this.http.get(this.app.api() + `/payments/participants/${id}`);
  }

  public makePayment(idevent: string, body: any, type: string ) {

    return this.http.post( this.app.api() + `/payments/imoje/${type}/${idevent}`, body);
  }

}
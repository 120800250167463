import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent  } from '@angular/common/http';
import { Observable } from 'rxjs';

export class AuthInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        req.headers.set('rejectUnauthorized', 'false');

        if ( localStorage.Token ) {
            const newReq = req.clone({headers: req.headers.set('x-access-token', localStorage.Token) });
            return next.handle(newReq);
        }


        return next.handle(req);
    }

}